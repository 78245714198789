import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import imgScreens from "../../assets/image/mobile-shifts-scheduling-app.png";
import imgScreens2x from "../../assets/image/mobile-shifts-scheduling-app@2x.png";
import imgAnyDevices from "../../assets/image/run-any-devices-any-where.svg";
import imgAS from "../../assets/image/App_Store_Badge.svg";
import imgGP from "../../assets/image/Google_Play_Store_badge_EN.svg";
import installAppQrCode from "../../assets/image/install_app_qr_code.png";
import { isIOS } from "../../utils/getOS";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section2 bg-default-3 pt-12 pb-5 pt-lg-19 pb-lg-23">
        <Container>
          <Row className="justify-content-center">
            <Col md="10" lg="8" xl="7">
              <div className="section-title text-center mb-11 mb-lg-11">
                <h2 className="gr-text-4 mb-6">Any device. Anywhere.</h2>
                <img
                  src={imgAnyDevices}
                  alt="Works on any devices and platform"
                  width="180"
                  className="py-6"
                />
                <p className="gr-text-8 px-lg-8 mb-0">
                  Works everywhere you do. Perform smoothly on both iOS and
                  Android devices.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="10" sm="6" md="6" lg="6" xl="5">
              <Row className="justify-content-center align-items-center">
                <Col xs="8" sm="8" md="8" lg="6" xl="6">
                  <div className="download-btns">
                    <div className="mb-6 text-center">
                      <a href="https://apps.apple.com/app/apple-store/id1577132228?pt=122904235&ct=landing_page&mt=8">
                        <img
                          src={imgAS}
                          alt="Apple AppStore Download"
                          className="w-100"
                        />
                      </a>
                    </div>
                    {// Hide this button to pass appstore review
                    // Because this page can be acccessed in Product Guide
                    isIOS ? null : (
                      <p className="text-center">
                        <a href="https://play.google.com/store/apps/details?id=com.camelo">
                          <img
                            src={imgGP}
                            alt="Google PlayStore Download"
                            className="w-100"
                          />
                        </a>
                      </p>
                    )}
                  </div>
                </Col>
                <Col xs="8" sm="8" md="8" lg="5" xl="5">
                  <div className="download-btns">
                    <p className="text-center">
                      <img
                        src={installAppQrCode}
                        alt="Install app QR code"
                        className="w-100 gr-image-box-shadow"
                      />
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="bg-default-3 pt-12">
          <Container>
            <Row className="align-items-start justify-content-center">
              <Col sm="12" lg="12" className="text-center">
                <LazyLoadImage
                  alt="Works on any devices and platforms"
                  src={imgScreens}
                  srcset={imgScreens + " 1x, " + imgScreens2x + " 2x"}
                  className="w-100"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Content;
