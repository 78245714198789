import React from "react";

import {Container, Row, Col} from "react-bootstrap";

import imgNaomi from "../../assets/image/customers/naomie.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section2 pt-12 pb-5 pt-lg-19 pb-lg-23 ">
        <Container>
          <Row className="justify-content-center">
            <Col md="10" lg="8" xl="7">
              <div className="section-title text-center mb-11 mb-lg-19">
                <h2 className="gr-text-4 mb-6">What To Expect</h2>
                <p className="gr-text-8 px-lg-4">
                  It's easy and free to get started. You have our team here to help you anytime.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-stretch justify-content-center">
            <Col sm="12" lg="6">
              <div className="what-to-expect content-widget-1 pr-lg-16">
                <div className="media single-widget pb-7">
                  <div className="count circle-sm bg-red text-white mr-8">
                    <span className="text-primary--light-only gr-text-9">
                      1
                    </span>
                  </div>
                  <div className="media-body">
                    <h3 className="gr-text-7">Test flight</h3>
                    <p className="gr-text-9">
                      We offer a playground demo account so that you could give Camelo a flight test. Simply input your company details, and learn how you can customize Camelo to your business needs.
                    </p>
                  </div>
                </div>
                <div className="media single-widget pb-7">
                  <div className="count circle-sm bg-green text-white mr-8">
                    <span className="text-primary--light-only gr-text-9">
                      2
                    </span>
                  </div>
                  <div className="media-body">
                    <h3 className="gr-text-7">Implement</h3>
                    <p className="gr-text-9">
                      Sign up with Camelo. Implement Camelo yourself, with tutorials available on our site. Or contact our team to help you set up.
                    </p>
                  </div>
                </div>
                <div className="media single-widget pb-7">
                  <div className="count count-last-child circle-sm bg-blue text-white mr-8">
                    <span className="text-primary--light-only gr-text-9">
                      3
                    </span>
                  </div>
                  <div className="media-body">
                    <h3 className="gr-text-7">Onboard with ease</h3>
                    <p className="gr-text-9">
                      Introduce Camelo to your staff. It's easy to pick up, so there's nothing to worry about.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm="12" lg="6" className="mb-10 mb-lg-0 py-8 rounded-xl text-left align-items-start d-flex"
              style={{background: "#FBDD5B url(" + imgNaomi + ") no-repeat right bottom", backgroundSize: "auto 50%"}}>
              <div class="text-end mx-8 mt-8">
                <h3 className="gr-text-5 mr-9 open-quote gr-color-blackish-blue-opacity-9">I was able to set everything up on my own. It was so easy, I didn't need to contact the support team.
                </h3>
                <p className="pt-4">
                  <strong>Naomie Grant</strong>, Owner
                  <br />
                  Lotus Life Spa
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
