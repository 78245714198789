import React, {useState} from "react";
import {Container, Row, Col, Collapse} from "react-bootstrap";
import imgC1 from "../../assets/image/support247.png";
import {LazyLoadImage} from 'react-lazy-load-image-component';

const Content = () => {
  const [openItem, setOpenItem] = useState(1);

  return (
    <>
      {/* <!-- Content section --> */}
      <div className="content-section pt-13 pt-lg-28 pb-15 pb-lg-29 bg-blackish-blue dark-mode-texts">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md="10" lg="6" xl="5">
              <div className="section-title content-text mb-13 mb-lg-16">
                <p className="gr-text-8 text-green">
                  More than just software
                </p>
                <h2 className="title gr-text-3 mb-6">
                  We are here for you, anytime.
                </h2>
              </div>
              <div className="content-widget">
                <Row>
                  <Col
                    md="6"
                    lg="9"
                    xl="10"
                    data-aos="fade-right"
                    data-aos-duration="750"
                  >
                    <div className="single-widget media mb-9 mb-lg-12">
                      <div className="widget-icon circle-30 bg-green mr-7">
                        <i className="icon icon-check-2-2 text-white"></i>
                      </div>
                      <div className="widget-text">
                        <h3 className="w-title gr-text-7">
                          Easy and free to get started
                        </h3>
                        <p className="gr-text-9 mb-0">
                          Camelo is simple to set up, pick up, and use. It's convenient and time-saving for both employers and employees.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="9"
                    xl="10"
                    data-aos="fade-right"
                    data-aos-duration="1300"
                  >
                    <div className="single-widget media mb-9 mb-lg-0">
                      <div className="widget-icon circle-30 bg-green mr-7">
                        <i className="icon icon-check-2-2 text-white"></i>
                      </div>
                      <div className="widget-text">
                        <h3 className="w-title gr-text-7">24/7 hour support</h3>
                        <p className="gr-text-9 mb-0">
                          Our team is here to give you personalized support within the hour.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col
              md="10"
              lg="6"
              xl="6"
              className="offset-xl-1 mt-9 mt-lg-0 "
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <LazyLoadImage
                alt="247 support"
                src={imgC1}
                className="w-100 mt-12"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
