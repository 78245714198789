import React, {useRef} from "react";
import styled from "styled-components";
import Slider from "react-slick";
import {Container, Row, Col, OverlayTrigger, Tooltip} from "react-bootstrap";
import {LazyLoadImage} from 'react-lazy-load-image-component';

import imgStars from "../../assets/image/svg/five-stars-rating.svg";

import imgC1 from "../../assets/image/customers/adam-miller.png";
import imgC2 from "../../assets/image/customers/sharper-shop.png";
import imgC3 from "../../assets/image/customers/carpenter.png";
import imgC4 from "../../assets/image/customers/health-clinics.png";
import imgC5 from "../../assets/image/customers/safari-lawn-care.png";

const SliderStyled = styled(Slider)`
  .slick-slide div {
    &:focus {
      outline: nnone !important;
    }
  }
`;

const items = [
  {
    title: "Owner",
    cover: imgC1,
    brandName: "J&D Cafe",
    fname: "Jared",
    lname: "Lebsack",
    whattheysaid: "All of my staff's shifts, tasks, and time-off requests are in one place—my phone. Managing my staff has become easier with just a few touches on the screen.",
  },
  {
    title: "Co-owner",
    cover: imgC2,
    brandName: "Sharper Shop",
    fname: "Callum",
    lname: "Campbell",
    whattheysaid: "We've saved lots of time on managing availability and time-off, as well as planning shifts and assigning tasks.",
  },
  {
    title: "Manager",
    cover: imgC3,
    brandName: "Ace & Hammer Builders",
    fname: "Andy",
    lname: "King",
    whattheysaid: "We don't have to jump between spreadsheets, group chats, and emails to know who's working and who's not anymore.  ",
  },
  {
    title: "GM",
    cover: imgC4,
    brandName: "Charleston Clinics",
    fname: "Seth",
    lname: "Johnston",
    whattheysaid: "I like how my staff can open the app and view their shifts right away.",
  },
  {
    title: "Owner",
    cover: imgC5,
    brandName: "Safari Lawn Care",
    fname: "Dhiraj",
    lname: "Dalal",
    whattheysaid: "No shifts are forgotten, no tasks are unfinished, and I always know what my staff are doing.",
  },
];

const Jobs = () => {
  const elSlider = useRef();

  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
    className: "single-slide",

    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* <!-- Jobs section --> */}
      <div className="job-site-page feature-section bg-default-6 bg-pattern pattern-5 pt-13 pt-lg-24 pb-2 pb-lg-22">
        <Container>
          <Row className="justify-content-lg-between align-items-start pb-5 pb-sm-0">
            <Col sm="8" md="6" lg="6" xl="5">
              <div className="section-title mb-9 mb-sm-13 mb-lg-21">
                <h2 className="title gr-text-4 mb-6 pl-6">People are talking</h2>
              </div>
            </Col>
            <Col sm="4" md="6" lg="4" xl="3">
              <div className="job-feature-slider-arrows ml-auto mr-6">
                <button
                  className="slick-prev slick-arrow"
                  aria-label="Previous"
                  type="button"
                  onClick={() => {
                    elSlider.current.slickPrev();
                  }}
                >
                  Previous
                </button>
                <button
                  className="slick-next slick-arrow"
                  aria-label="Next"
                  type="button"
                  onClick={() => {
                    elSlider.current.slickNext();
                  }}
                >
                  Next
                </button>
              </div>
            </Col>
          </Row>
          <div
            className="job-feature-slider"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <SliderStyled ref={elSlider} {...slickSettings}>
              {items.map(
                (
                  {link = "/#", whattheysaid, fname, lname, cover, title, brandName},
                  index
                ) => (
                  <div key={index}>
                    <div
                      className="feature-widget overflow-hidden focus-reset bg-white d-flex flex-column min-height-px-297 border rounded-10 mx-6">
                      <div className="gr-hover-zoom-img card-img overflow-hidden rounded-0">
                        <LazyLoadImage
                          alt={brandName}
                          src={cover}
                          className="zoomable w-100 rounded-top-10"
                        />
                      </div>
                      <div className="pt-8 pb-8 pl-6 pl-lg-6 pr-md-7 pr-lg-6">
                        <div class="pb-2">
                          <img src={imgStars} style={{width: "5rem"}} />
                        </div>
                        <div className="mb-auto" style={{height: "142px"}}>
                          <p className="gr-text-10 text-blackish-blue mb-0">
                            {whattheysaid}
                          </p>
                        </div>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="button-tooltip-2">Hi, I'm {fname} <span role="img" aria-label="hi" className="wave-animation">👋</span></Tooltip>}
                        >
                          <div className="mt-7">
                            <div className="text">
                              <div>—</div>
                              <p className="gr-text-12 mb-0">
                                {fname + " " + lname + " (" + title + ")"}
                              </p>
                              <p className="gr-text-12 mb-0">
                                <em>{brandName}</em>
                              </p>
                            </div>
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                )
              )}
            </SliderStyled>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Jobs;
